<script>
import axios from "axios";
import skeleton from "@/components/custom/skeleton.vue";

export default {
  name: "TotalOrdersStatus",
  props: {
    company_type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      total_reserved_price: 0,
      completed_act_price: 0,
      request: {
        status: 'loading'
      }
    }
  },
  components: {skeleton},
  methods: {
    async getCounterpartyFinanceStatus() {
      this.request.status = 'loading'
      try {
        let response = await axios.get(`${this.company_type}/expanse_status/${this.$route.params.slug}/`)
        this.total_reserved_price = response.data.total_reserved_price || 0
        this.completed_act_price = response.data.completed_act_price || 0
      } catch {
        this.request.status = 'error'
        this.total_reserved_price = 0
        this.completed_act_price = 0
      }
      this.request.status = 'success'
    }
  },
  mounted() {
    if (this.company_type) {
      this.getCounterpartyFinanceStatus()
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-title>Complete The Profile</b-card-title>
    <div v-if="['loading', 'success'].includes(request.status)">
      <div class="table-responsive my-3">
        <table class="table table-nowrap">
          <thead>
          <tr>
            <th scope="col" class="ps-0">Total Reserved Price</th>
            <th scope="col" class="text-end" style="min-width: 65px">
              <skeleton :loading="request.status === 'loading'" show_data_after_loading>
                <a class="fw-semibold">
                  {{ parseFloat(total_reserved_price || 0).toLocaleString('en-UK') }}
                </a>
                <i class="bx bx-dollar align-middle fs-5 text-muted ms-1"></i>
              </skeleton>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="align-middle">
            <th scope="row" class="ps-0">
              Completed Act Price
            </th>
            <td class="text-end" style="min-width: 65px">
              <skeleton :loading="request.status === 'loading'" show_data_after_loading>
                <a class="text-success fw-semibold">
                  {{ parseFloat(completed_act_price || 0).toLocaleString('en-UK') }}
                </a>
                <i class="bx bx-dollar align-middle fs-5 text-muted ms-1"></i>
              </skeleton>
            </td>
          </tr>
          <tr class="align-middle">
            <th scope="row" class="ps-0">
              Incomplete Act Price
            </th>
            <td class="text-end" style="min-width: 65px">
              <skeleton :loading="request.status === 'loading'" show_data_after_loading>
                <a class="text-danger fw-semibold">
                  {{
                    (parseFloat(total_reserved_price || 0) - parseFloat(completed_act_price || 0))
                        .toLocaleString('en-UK')
                  }}
                </a>
                <i class="bx bx-dollar align-middle fs-5 text-muted ms-1"></i>
              </skeleton>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <skeleton :loading="request.status === 'loading'" show_data_after_loading>
        <div
            class="progress animated-progess custom-progress progress-label"
        >
          <div
              class="progress-bar bg-success"
              role="progressbar"
              :style="{width: ((completed_act_price / total_reserved_price) * 100) + '%' }"
              :aria-valuenow="completed_act_price"
              aria-valuemin="0"
              :aria-valuemax="total_reserved_price"
              :class="{
                'bg-danger': ((completed_act_price / total_reserved_price) * 100) < 50,
                'bg-warning': ((completed_act_price / total_reserved_price) * 100) >= 50 && ((completed_act_price / total_reserved_price) * 100) < 75,
                'bg-success': ((completed_act_price / total_reserved_price) * 100) >= 75
              }"
          >
            <div class="label">
              {{ ((completed_act_price / (total_reserved_price || 1)) * 100).toFixed(1) + '%' }}
            </div>
          </div>
        </div>
      </skeleton>
    </div>
    <div v-else-if="request.status === 'error'" class="pt-3">
      <b-alert show variant="danger" class="border-0 mb-0 " role="alert">
        <strong>Couldn't load counterparty finance status !</strong>
      </b-alert>
    </div>
  </b-card>
</template>

<style scoped>

</style>